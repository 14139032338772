$zindex: (
  errrorNotification: 6,
  loadingScreen: 5,
  loadingScreenOpacityLayer: 4,
  helpBox: 3,
  header: 2,
  welcomeScreen: 2,
  editorIframe: 1,
  iframeContainer: 1,
  mobileFooter: 1
);
