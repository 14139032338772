@import "../App/zindex";

.iframeContainer {
  height: 100%;
  background-color: #dbe2e6;
  position: relative;
  z-index: map-get($zindex, iframeContainer);
}
.show {
  opacity: 1 !important;
  transition: all 0.3s ease-in-out;
}

.hide {
  opacity: 0;
}

.iframeWrapper {
  height: 100%;
  background-color: #dbe2e6;
}

.editorIframe {
  border: 0;
  width: 100%;
  height: calc(100% - 56px);
  position: absolute;
  z-index: map-get($zindex, editorIframe);
}

.siteRenderer {
  height: 100%;
}

.frameMobile {
  top: 64px;
  height: calc(100% - 215px);
}

.mobile {
  opacity: 0;
  position: absolute;
  width: 353px;
  height: 45px;
  background-image: url("../../assets/images/phone-top.v3.png");
  top: 20px  
}

.mobileBg {
  opacity: 0;
  height: calc(100% - 217px);
  width: 376px;
  left: calc(50% - 197px);
  background-image: url("../../assets/images/phone-bg.v3.png");
  background-repeat: repeat-y;
  position: absolute;
  top: 65px;
}

.mobileFooter {
  opacity: 0;
  width: 377px;
  height: 108px;
  background-image: url("../../assets/images/phone-bottom.v3.png");
  bottom: 44px;
  position: absolute;
  z-index: map-get($zindex, mobileFooter);
}