@import "../App/zindex";

.container {
  position: fixed;
  right: 10px;
  width: 444px;
  box-shadow: 0 6px 6px 0 rgba(22, 45, 61, 0.06), 0 0 18px 0 rgba(22, 45, 61, 0.12);
  top: 74px;
  border-radius: 8px;
  z-index: map-get($zindex, welcomeScreen);
}

.container {
  button { 
    height: 30px !important;
    width: 150px;
  }
}
