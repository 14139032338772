.root {
  border-radius: 0 !important;
  margin-bottom: 6px;

  .content {
    padding: 24px;
  }

  .header {
    display: flex;
    align-items: center;
    .name {
      margin-right: 10px;
      margin-left: 12px;
    }
  }
  .commentText {
    margin-top: 16px;
    word-wrap: break-word;
  }
  .footer {
    display: flex;
    justify-content: space-between;
    margin-top: 18px;
    .tags {
      > * {
        margin-left: 4px;
      }
      .resolvedTag::after {
        content: "|";
        margin-left: 4px;
      }
    }
  }
}
