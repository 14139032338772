@import "zindex";

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  margin: 0;
  height: 100%;
}

.root {
  height: 100%;
  overflow: hidden;
}

:global {
  #root {
    height: 100%;
  }
}

.errorNotification {
  position: fixed;
  z-index: map-get($zindex, errrorNotification);
  margin: 5px auto;
  left: 0;
  right: 0;
}