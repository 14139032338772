@import "../App/zindex";

.root {
  display: flex;
  flex: 1;
  align-items: center;
  padding: 0px 24px;
  height: 62px;
  background-color: #fff;
  margin: 0 auto;
  position: relative;
  z-index: map-get($zindex, header);
  box-shadow: 0 2px 4px 0 rgba(22, 45, 61, 0.12),
    0 0 6px 0 rgba(22, 45, 61, 0.12);

  .separator{
    width: 1px;
    height: 18px;
    border-right: solid 1px #dedede;
    margin-right: 16px;
    margin-left: 10px;
  }

  .logoSection,
  .viewType {   
    flex: 1;
    display: flex;
    align-items: center;
  }
  .viewType {
    justify-content: center;
  }

  .right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .unreadCountBadge {    
    margin-right: 18px;
  }
  .pro {
    padding: 0 16px;
  }

  .tabs {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      position: relative;
      padding: 18px 18px 16px;
      margin: 0 0 -1px;
      border-bottom: 3px solid transparent;
      white-space: nowrap;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }

    .active {
      border-color: #3899ec;

      button {
        color: #3899ec;
      }
    }
  }
}
