$positionFromTop: 62px;
$listHeaderHeight: 48px;
$listWidth: 348px;

.root {
  background-color: #FFFFFF;
  width: $listWidth;
  position: fixed;
  right: -$listWidth;
  height: 100%;
  top: $positionFromTop;
  box-shadow: 0 6px 6px 0 rgba(22, 45, 61, 0.06), 0 0 18px 0 rgba(22, 45, 61, 0.12);
  border-top: 1px solid rgba(22, 45, 61, 0.12);
  transition: right .5s ease-in-out;
  &.shown {
    right: 0;
  }
  .listHeader {
    display: flex;
    justify-content: space-between;
    height: $listHeaderHeight;
    padding: 14px 26px;
    border-bottom: 1px solid #dfe5eb;
  }
  .commentsWrapper {
    height: calc(100vh - #{$positionFromTop + $listHeaderHeight + 30});
    overflow: auto;
    background-color: #f0f4f7;
  }

  .commentsWrapper.responsive {
    height: calc(100vh - #{$positionFromTop});    
  }

}
