@import "../App/zindex";

.helpBoxWrapper {
  position: fixed;
  left: calc(50% - 250px);
  top: calc(50% - 275px);
  z-index: map-get($zindex, helpBox);
  box-shadow: 0 0 14px 0 rgba(22,45,61,.36);
  border-radius: 8px;
  .closeButton {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  iframe {
    width: 500px;
    height: 550px;
    background-color: #fff;
    border: 0;
    border-radius: 8px;
  }
}
