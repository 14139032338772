@import "../../App/zindex";

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: map-get($zindex, loadingScreen);
}

.messageModalLayout {
  position: relative;
  z-index: map-get($zindex, loadingScreenOpacityLayer) + 1
}

.opacityLayer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  background-color: black;
  z-index: map-get($zindex, loadingScreenOpacityLayer);
}
